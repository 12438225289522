import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Contact from '../views/Contact'

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Jocy = styled.div`
  color: #f65d6f;
  display: inline-block;
  font-weight: 700;
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={2}>
      <Hero offset={0}>
        <BigTitle>
          Hello, <br /> We are <Jocy>jocy</Jocy>
        </BigTitle>
        <Subtitle>We’re a High Quality Software Company Building State of the Art Web Applications in Marketing Tech.</Subtitle>
      </Hero>
      <Contact offset={1}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
            Say <a href="mailto:hello@jocy.co">Hi!</a> <br /><br />
            Jocy Holdings <br/>
            Cymaplus Group Limited, Naxou 4 <br />
            1st floor, Flat/Office 101, 1070, Nicosia, Cyprus
          </ContactText>
        </Inner>
        <Footer>
          &copy; 2021 Jocy
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
