import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { colors } from '../../tailwind'
import { Divider } from '../elements/Dividers'
import Content from '../elements/Content'
import { UpDown, UpDownWide } from '../styles/animations'
import SVG from '../components/SVG'

const Wrapper = styled.div`
  ${tw`w-full xl:w-2/3`};
`

const Hero = ({ children, offset }) => (
  <>
    <Divider speed={0.2} offset={offset}>
      <UpDown>
        <SVG icon="anger" hiddenMobile width={48} stroke={colors['grey-darkest']} left="80%" top="50%" />
        <SVG icon="creative" hiddenMobile width={6} stroke={colors['grey-darker']} left="90%" top="35%" />
        <SVG icon="whistle" width={16} stroke={colors['grey-darkest']} left="13%" top="85%" />
        <SVG icon="arrowUp" width={16} fill={colors['grey-darker']} left="70%" top="100%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="joker" hiddenMobile width={16} stroke={colors['grey-darkest']} left="80%" top="10%" />
        <SVG icon="hand" width={24} fill={colors['grey-darkest']} left="35%" top="70%" />
        <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
      </UpDownWide>
      <SVG icon="upDown" width={6} fill={colors['grey-darkest']} left="15%" top="30%" />
      <SVG icon="thirdeye" hiddenMobile width={24} stroke={colors['grey-darker']} left="1%" top="70%" />
      <SVG icon="observation" width={12} stroke={colors['grey-darkest']} left="80%" top="1%" />
      <SVG icon="upDown" width={8} fill={colors['grey-darkest']} left="95%" top="90%" />
      <SVG icon="creative" hiddenMobile width={24} stroke={colors['grey-darkest']} left="40%" top="50%" />
      <SVG icon="fish" width={8} stroke={colors['grey-darker']} left="25%" top="15%" />
      <SVG icon="circle" width={64} fill={colors["pink-lightest"]} left="95%" top="5%" />
      <SVG icon="fish" hiddenMobile width={12} stroke={colors['grey-darker']} left="25%" top="99%" />
      <SVG icon="box" hiddenMobile width={6} fill={colors['grey-darkest']} left="10%" top="10%" />
      <SVG icon="website" hiddenMobile width={12} stroke={colors['grey-darker']} left="40%" top="30%" />
      <SVG icon="joker" hiddenMobile width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="10%" top="100%" />
    </Divider>
    <Content speed={0.4} offset={offset}>
      <Wrapper>{children}</Wrapper>
    </Content>
  </>
)

export default Hero

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number.isRequired,
}
